import { InjectionToken } from '@angular/core';

const NAMESPACE = 'ffpcv_';

export const COLLECTIONS = {
  USERS: NAMESPACE + 'users',
  SUGGESTED_USERS: NAMESPACE + 'suggested_users',
  USERS_LICENSES: 'user_licenses',
  CLUBS: NAMESPACE + 'clubs',
  TEAMS: NAMESPACE + 'teams',
  COMPETITION: NAMESPACE + 'competitions',
  SEASON: NAMESPACE + 'seasons',
  SANCTIONS: NAMESPACE + 'sanctions',
  SEASON_RATES: 'season_rates',
  MATCH: NAMESPACE + 'matches',
  MOVEMENTS: NAMESPACE + 'movements',
  LICENSES_RATES: NAMESPACE + 'licenses_rates',
  REQUESTS: NAMESPACE + 'requests',
  TRANSFERS: NAMESPACE + 'transfers',
  UNSUBSCRIBES: NAMESPACE + 'unsubscribes',
  INVOICES: NAMESPACE + 'invoices',
  RECEIPTS: NAMESPACE + 'receipts',
  JOURNEYS: 'season_journeys',
  NOTIFICATIONS: 'notifications',
  NOTIFICATIONS_TEMPLATES: 'notifications_templates',
  EMAILS: 'emails',
  EMAILS_TEMPLATES: 'emails_templates',
  MASTERS: 'masters',
  MASTERS_SETTINGS_DOC: 'settings',
};

export const ENVIRONMENT = new InjectionToken<any>('environment');
